import React from "react"
import { Link } from "gatsby"
import "./menu.css"

const activeClassName = "underline font-semibold"
const defaultClassName =
  "inline-block text-gray-900 hover:text-gray-900 hover:underline whitespace-no-wrap"

const Menu = () => {
  return (
    <nav
      className="w-full block border-gray-600 border-t-2 border-b-2 pt-2 pb-2"
      id="nav-content"
    >
      <ul className="list-reset flex flex-wrap flex-1 items-center justify-center">
        <li className="my-2 mx-2">
          <Link
            title="Hjem"
            activeClassName={activeClassName}
            className={defaultClassName}
            to="/"
          >
            Takk
          </Link>
        </li>
        <li className="my-2 mx-2">
          <Link
            title="Sted"
            activeClassName={activeClassName}
            className={defaultClassName}
            to="/bilder"
          >
            Bilder
          </Link>
        </li>
        <li className="my-2 mx-2">
          <Link
            title="Sted"
            activeClassName={activeClassName}
            className={defaultClassName}
            to="/video"
          >
            Video
          </Link>
        </li>
      </ul>
    </nav>
  )
}

export default Menu
