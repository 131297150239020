/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import "./layout.css"
import Title from "./title"
import Menu from "./menu"

const Layout = ({ children, pageTitle }) => {
  return (
    <div className="layout max-w-screen-lg m-auto sm:mt-4 text-center">
      <Title />
      <Menu />
      <div className="border-gray-600 border-l-2 border-r-2">
        <main className="w-full p-4">
          <div className="max-w-2xl  m-auto">
            <h2 className="py-4">{pageTitle}</h2>
            {children}
          </div>
        </main>
        <footer className="sm:mt-4 mb-8">
          Karoline{" "}
          <span role="img" aria-label="Hjerte emoji">
            ❤️
          </span>{" "}
          Thomas
        </footer>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
